/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      	init: function() {

      		$(document).ready(function(){

      			$(".sticky-blog-sidebar").stick_in_parent({
      				"parent" : ".container"
      			});

      			$(window).load(function(){
  					console.log("Creating the grid.");
  					$('.grid-wrapper').masonry({
					    columnWidth: 200,
					    itemSelector: '.grid-item'
					});
  				});

      			/* here is the call to initiate slideshows */
      			$(window).load(function(){

	      			window.setTimeout(function(){

						$(".slideshow").mCustomScrollbar({
							axis:"x",
							theme:"dark",
							scrollbarPosition:"inside",
							autoDraggerLength: false,
							scrollInertia : 400,
							contentTouchScroll:100,
							mouseWheel: {
								axis:"x",
								scrollAmount:2,
								invert:true
							},
							advanced: {
								updateOnContentResize:!0,
								updateOnImageLoad:!0,
								autoExpandHorizontalScroll:!0
							}

						});

						
						// set the click events
						var countImages = $(".images img").length; 

						$(".images img").click(function(e){
							var currentIndex = parseInt($(this).data("index"));

							var leftOffset = $(this).position().left;
							var width = $(this).width();
							var containerWidth = $(this).closest(".mCustomScrollbar").width();
							
							// first, check if it's the first or last image
							if(currentIndex == 1)
							{
								// first or last item, so just scroll to the edge
								$(".slideshow").mCustomScrollbar("scrollTo", 'left');
								return;
							}

							if(currentIndex == countImages)
							{
								$(".slideshow").mCustomScrollbar("scrollTo", 'right');
								return;
							}

							var scrollPosition = leftOffset - (containerWidth / 2 - (width/2));
							$(".slideshow").mCustomScrollbar("scrollTo", scrollPosition);
						});
						

						$(".loader-wrapper").fadeOut(375);

	      				window.setTimeout(function(){
	      					$(".slideshow").animate({"opacity" : 1}, 175);
	      				}, 100);


	      				var inTransition = false;

	      				$(".grid-item img").click(function(e){
	      					e.preventDefault();
	      					inTransition = true;

	      					var scrollTo = $(this).data("scroll-to");

	      					$("#chooseGrid").removeClass("active");
	      					$("#chooseCarousel").addClass("active");

	      					$(".photo-grid").fadeOut(175);
	      					$(".gallery-transition").fadeIn(250);
	      					$("html, body").animate({ scrollTop: ($(".gallery-navigation").offset().top - 75 ) }, 500);

	      					window.setTimeout(function(){
	      						$(".gallery-transition").fadeOut(175);
	      						$(".slideshow-wrapper").fadeIn(1000);

	      						window.setTimeout(function(){
	      							$(".images img").eq(parseInt(scrollTo)-1).click();
	      							inTransition = false;
	      						}, 500);

	      					}, 2250);
	      				});

	      				$("#chooseCarousel").click(function(e){
	      					e.preventDefault();
	      					if(inTransition == true)
	      					{
	      						return;
	      					}
	      					else
	      					{
	      						inTransition = true;
	      					}

	      					if($(this).hasClass("active"))
	      					{
	      						return;
	      					}
	      					else
	      					{
	      						$("#chooseGrid").removeClass("active");
	      						$(this).addClass("active");
	      					}

	      					$(".photo-grid").fadeOut(175);
	      					$(".gallery-transition").fadeIn(250);

	      					window.setTimeout(function(){
	      						$(".gallery-transition").fadeOut(175);
	      						$(".slideshow-wrapper").fadeIn(1000);
	      						inTransition = false;
	      					}, 2250);
	      				});

	      				$("#chooseGrid").click(function(e){
	      					e.preventDefault();

	      					if(inTransition == true)
	      					{
	      						return;
	      					}
	      					else
	      					{
	      						inTransition = true;
	      					}

	      					if($(this).hasClass("active"))
	      					{
	      						return;
	      					}
	      					else
	      					{
	      						$("#chooseCarousel").removeClass("active");
	      						$(this).addClass("active");
	      					}

	      					$(".slideshow-wrapper").fadeOut(175);
	      					$(".gallery-transition").fadeIn(250);

	      					window.setTimeout(function(){
	      						$(".photo-grid").css({"opacity" : 0}).show();
	      						wall.fitWidth();

	      						window.setTimeout(function(){
		      						$(".gallery-transition").fadeOut(175);
		      						$(".photo-grid").animate({"opacity" : 1}, 500)
		      						inTransition = false;
		      					}, 2250);

	      					}, 250);

	      					
	      				});


	      			}, 100);
      			});
				/* end call to slideshow initiate */
				$(".date-input input").prop("type", "date");
				$(document).bind('gform_post_render', function(){
    				$(".date-input input").prop("type", "date");
    			});

				$("#comment input[type=url], #comment textarea, #comment input[type=email], #comment input[type=text]").each(function(index, el){
					$(el).prop("placeholder", $(el).siblings("label").text());
				});

				$(".on-page").click(function(e){
					e.preventDefault();
					$(".comments-container").slideDown(175);
					$("html, body").animate({ scrollTop : ($(".comments-container").offset().top - 60) }, '500');
				});

				$(".g-nav-select").selectpicker();

				$("#g-nav-select").change(function(e){
					window.location = $(this).val();
				});

				var currentURL = window.location;
				$(".g-nav a").each(function(i, e){
					if($(e).prop("href") == currentURL)
					{
						$(e).addClass("active");
					}
				});

				$('.carousel').carousel({
					interval : false
				}).on('slide.bs.carousel', function (e){
				    var nextH = $(e.relatedTarget).height();
				    $(this).find('.active.item').parent().animate({ height: nextH }, 750);
				});

      			$('#toggleNavigation').click(function(e){
	      			e.preventDefault();
	      			$(this).toggleClass("active");
					$(this).find("#nav-icon").toggleClass('open');

					// toggle the overlay
					$(".menu-overlay").fadeToggle(250);
				});


      		});



        
      		


      	},
      	finalize: function() {
        	// JavaScript to be fired on all pages, after page specific JS is fired
      	}
    },
    	// Home page
    'home': {
      	init: function() {
        	// JavaScript to be fired on the home page
        	$(document).ready(function(){

        		

        	});
      	},
      	finalize: function() {
        	// JavaScript to be fired on the home page, after the init JS
      	}
    },
    
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
